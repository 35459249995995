<template>
  <div>
    <div class="list">
      <img class="no_data" src="../../assets/images/no_data.png" v-if="data.total == 0" />
      <router-link :to="{ name: 'materialdetail', params: { id: item.id } }" class="item" v-for="(item, index) in data.list"
        :key="index">
        <img class="img" :src="item.img_url" />
        <div class="title">{{ item.title }}</div>
        <div class="logo">
          <img class="icon" src="../../assets/images/cmf_colloct.png" />
          <span class="num">{{ item.click }}</span>
        </div>
      </router-link>
    </div>

    <div class="pagination_box" v-if="data.total>15">
      <el-pagination v-model:currentPage="data.pageindex" background layout="prev, pager, next, jumper"
        :page-size="data.pagesize" :total="data.total" />
    </div>

          <div class="popForm cn is-visible" v-if="data.pop_permission">
           <div class="pop_dialog">
                <div class="dialog_header">
                    <div class="dialog_title">请输入密码查看</div>
                </div>
                <div class="dialog_container">
                     <div class="label">查看密码</div>
                     <div class="dialog_input"><input type="password" v-model="data.password" placeholder="请输入密码"></div>
                     <span class="error">{{data.errortip}}</span>
                </div>
                <div class="dialog_footer">
                    <a href="javascript:void(0);" class="dialog_button button_primary" @click="submit()">确定</a>
                </div>
           </div>
      </div>

  </div>
</template>

<script setup>
import { getCurrentInstance, reactive, defineProps, watch } from 'vue';
import { useRoute, useRouter} from 'vue-router';
import { localStorage } from '../../utils/storage';

const _this = getCurrentInstance().appContext.config.globalProperties;
const $route = useRoute();
const $router = useRouter();
const props = defineProps(['keyword', 'selected_lingyu', 'selected_caizhi', 'selected_sort', 'selected_gongyi','selected_gongneng','is_collection']);

const pwd_key = 'pwd_key';
const pwd_real= 'Ubo83814997';
const data = reactive({
  pageindex: 1,
  pagesize: 15,
  total: 0,
  list: [],
  password:'',
  errortip:'',
  pop_permission:false,
});


watch([() => props.keyword, () => props.selected_lingyu,
() => props.selected_caizhi, () => props.selected_sort,
  () => props.selected_gongyi, () => props.selected_gongneng], () => {
  data.pageindex = 1;
  GetList();
});

watch(() => data.pageindex, () => {
  GetList();
});

var has_permission=CheckPermission();
if (has_permission){
  GetList();
}else{
  data.pop_permission=true;
}



function GetList() {
  var url = "/api/cailiaoguan.ashx?action=materiallist";
  _this.$http.post(url, {
    keyword: props.keyword, pageindex: data.pageindex, pagesize: data.pagesize,
    lingyu_id: props.selected_lingyu, caizhi_id: props.selected_caizhi, sort_type: props.selected_sort,
    craft_ids: props.selected_gongyi, gongneng_ids: props.selected_gongneng, is_collection:props.is_collection
  }).then(function (res) {
    if (res.data.status == 1) {
      data.list = res.data.ds;
      data.total = res.data.total;
    } else if (res.data.status == 3) {
      localStorage.remove('user_info');
      $router.push({ name: 'login' });
    }else {
      console.log(res);
    }
  }.bind(this)).catch(function () {
  });
}

function CheckPermission(){
    if (localStorage.get('user_info')) return true;
    var store_pwd = localStorage.get(pwd_key);
    if (store_pwd==pwd_real) return true;
    return false;
}

function submit(){
  if (!data.password){
    data.errortip='请输入密码';
    return;
  }
  if (data.password!=pwd_real){
    data.errortip='密码错误';
    return;
  }
   GetList();
  data.pop_permission=false;
  localStorage.set(pwd_key,data.password,60*60*24*3);
}


</script>
