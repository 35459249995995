<template>
  <div class="w_material_list">
    <div class="top_box">
      <div class="keyword_box">
        <Keyword @goSearch="goSearch" :search_text="$route.params.keyword"></Keyword>
      </div>
      
      <!-- <div class="select_filter">
        <div class="l_text">所有分类></div>
        <div class="r_box">
          <div class="item" @click="DeleteLingyu" v-if="data.selected_lingyu_text">
            <span>领域: </span>
            <span>{{ data.selected_lingyu_text }}</span>
            <img class="out" src="../../assets/images/ico_out.png" />
          </div>
          <div class="item" @click="DeleteCaizhi" v-if="data.selected_caizhi_text">
            <span>材质: </span>
            <span>{{ data.selected_caizhi_text }}</span>
            <img class="out" src="../../assets/images/ico_out.png" />
          </div>

          <template v-for="(item,index) in data.selected_gongyi_text">
            <div class="item" :key="index" v-if="item.text.length>0"  @click="DeleteGongyiItem(index,item)">
              <span>{{ item.title }}: </span>
              <span v-for="(item2,index2) in item.text" :key="index2">&nbsp;&nbsp;{{ item2.title }} </span>
              <img class="out" src="../../assets/images/ico_out.png" />
            </div>
          </template>

          <template v-for="(item,index) in data.selected_gongneng_text">
            <div class="item" :key="index" v-if="item.text.length>0" @click="DeleteGongnengItem(index,item)">
              <span>{{ item.title }}: </span>
              <span v-for="(item2,index2) in item.text" :key="index2">&nbsp;&nbsp;{{ item2.title }} </span>
              <img class="out" src="../../assets/images/ico_out.png" />
            </div>
          </template>

          <div class="item" @click="DeleteSort" v-if="data.selected_sort_text">
            <span>排序: </span>
            <span>{{ data.selected_sort_text }}</span>
            <img class="out" src="../../assets/images/ico_out.png" />
          </div>
        </div>
      </div> -->
      <!-- <div class="filter_box">
        <CmfDropdownCom :title="'领域'" :list="data.list_lingyu" :selected="data.selected_lingyu" @GoFilter="GoFilterLingyu"></CmfDropdownCom>
        <CmfDropdownCom :title="'材质'" :list="data.list_caizhi" :selected="data.selected_caizhi" @GoFilter="GoFilterCaizhi"></CmfDropdownCom>
        <CmfDropMore :title="'工艺'" :list="data.list_gongyi" @GoSelected="GoSelectedGongyi" @GoCancel="GoCancelGongyi" @GoConfirm="GoConfirmGongyi"></CmfDropMore>
        <CmfDropSimple :title="'功能'" :list="data.list_gongneng"  @GoSelected="GoSelectedGongneng" @GoCancel="GoCancelGongneng" @GoConfirm="GoConfirmGongneng"></CmfDropSimple>
        <CmfDropdownCom :title="'排序'" :list="data.list_sort" :selected="data.selected_sort" @GoFilter="GoFilterSort"></CmfDropdownCom>
      </div> -->
    </div>

    <div class="material_list">
      <MaterialList :keyword="data.keyword" :selected_lingyu="data.selected_lingyu"
        :selected_caizhi="data.selected_caizhi" :selected_sort="data.selected_sort"
        :selected_gongyi="data.selected_gongyi" :selected_gongneng="data.selected_gongneng"
      ></MaterialList>
    </div>

  </div>
</template>

<script setup>
import { reactive, getCurrentInstance, onActivated } from 'vue';
import Keyword from '../../components/Common/Keyword.vue';
import MaterialList from '../../components/Material/Listcom.vue'
import CmfDropdownCom from '../../components/Common/CmfDropdownCom.vue'
import CmfDropMore from '../../components/Material/CmfDropMore.vue'
import CmfDropSimple from '../../components/Material/CmfDropSimple.vue'
import { useRoute } from 'vue-router';

const $route = useRoute();
const _this = getCurrentInstance().appContext.config.globalProperties;
const data = reactive({
  keyword: $route.params.keyword,
  list_lingyu:[],
  selected_lingyu:'',
  selected_lingyu_text: '',
  list_caizhi: [],
  selected_caizhi: '',
  selected_caizhi_text:'',
  list_sort: [{ title: '排序', id: ''}, { title: '最新发布', id: 3 }, { title: '查看最多', id: 1 }],
  selected_sort: '',
  selected_sort_text:'',
  list_gongyi: [],
  selected_gongyi: '',
  selected_gongyi_text:[],
  list_gongneng: [],
  selected_gongneng: '',
  selected_gongneng_text: '',
});

onActivated(()=>{
  data.keyword = $route.params.keyword;
});

function goSearch(e){
  data.keyword = e;
}

function GoFilterLingyu(e){
  data.selected_lingyu = e.id;
  data.selected_lingyu_text = e.title == '全部' ? '' : e.title;
}
function DeleteLingyu(){
  data.selected_lingyu = '';
  data.selected_lingyu_text = '';
}
function GoFilterCaizhi(e) {
  data.selected_caizhi = e.id;
  data.selected_caizhi_text = e.title == '全部' ? '' : e.title;
}
function DeleteCaizhi() {
  data.selected_caizhi = '';
  data.selected_caizhi_text = '';
}
function GoFilterSort(e) {
  data.selected_sort = e.id;
  data.selected_sort_text = e.title == '排序' ? '' : e.title;
}
function DeleteSort() {
  data.selected_sort = '';
  data.selected_sort_text = '';
}
function GoSelectedGongyi(e){
  data.list_gongyi[e.outer].children[e.inner].selected ? data.list_gongyi[e.outer].children[e.inner].selected = false : data.list_gongyi[e.outer].children[e.inner].selected = true;
}
function DeleteGongyiItem(index){
  data.selected_gongyi_text[index].text = '';
  data.list_gongyi[index].children.map(item=>{
    if(item.selected)item.selected=false;
  });

  let temp_craft_ids = [];
  data.list_gongyi.map((item) => {
    item.children.map((item2) => {
      if (item2.selected == true) temp_craft_ids.push(item2.id);
    });
  });

  data.selected_gongyi = temp_craft_ids.join(',');
}

function DeleteGongnengItem(index) {
  data.selected_gongneng_text[index].text = '';
  data.list_gongneng[index].children.map(item => {
    if (item.selected) item.selected = false;
  });

  let temp_gongneng_ids = [];
  data.list_gongneng.map((item) => {
    item.children.map((item2) => {
      if (item2.selected == true) temp_gongneng_ids.push(item2.id);
    });
  });

  data.selected_gongneng = temp_gongneng_ids.join(',');
}

function GoSelectedGongneng(e){
  //取消
  if (data.list_gongneng[e.outer].children[e.inner].selected){
    data.list_gongneng[e.outer].children.map(item => {
      item.selected = false;
    });
  }else {
    //选中 1.取消所有子项选中状态  2.选中当前子项
    data.list_gongneng[e.outer].children.map(item => {
      item.selected = false;
    });
    data.list_gongneng[e.outer].children[e.inner].selected = true;
  }
}

//工艺重置
function GoCancelGongyi(){
  data.list_gongyi.map(item => {
    item.children.map(item2 => {
      if (item2.selected == true) item2.selected = false;
    });
  });

  // data.selected_gongyi_text = [];
}

//工艺确认
function GoConfirmGongyi(){
  let temp_craft_ids = [];
  let temp_gongyi_text = [];
  data.list_gongyi.map((item,index) => {
    temp_gongyi_text[index] = {
      title: item.title,
      text:[]
    }
    item.children.map((item2) => {
      if (item2.selected == true) {        
        temp_craft_ids.push(item2.id);
        temp_gongyi_text[index].text.push(item2); 
      }
    });
  });

  data.selected_gongyi = temp_craft_ids.join(',');
  data.selected_gongyi_text = temp_gongyi_text;
}

//功能重置
function GoCancelGongneng() {
  data.list_gongneng.map(item => {
    item.children.map(item2 => {
      if (item2.selected == true) item2.selected = false;
    });
  });

  // data.selected_gongneng_text = [];
}
//功能确认
function GoConfirmGongneng() {
  let temp_gongneng_ids = [];
  let temp_gongneng_text = [];
  data.list_gongneng.map((item, index) => {
    temp_gongneng_text[index] = {
      title: item.title,
      text: []
    }
    item.children.map(item2 => {
      if (item2.selected == true) {
        temp_gongneng_ids.push(item2.id);
        temp_gongneng_text[index].text.push(item2);
      }
    });
  });

  data.selected_gongneng = temp_gongneng_ids.join(',');
  data.selected_gongneng_text = temp_gongneng_text;
}

function GetFilterTags(_type) {
  var url = "/api/cailiaoguan.ashx?action=GetFilterTags";
  _this.$http.post(url, { type: _type }).then(function (res) {
    if (res.data.status == 1) {
      if (_type == 2) {
        res.data.data.map(item => {
          item.children.map(item2 => {
            item2.selected = false;
          });
        });
        data.list_gongneng = res.data.data;
      }
      if(_type == 4){
        data.list_lingyu = res.data.data;
        data.list_lingyu.unshift({ id: '', title: '全部' });
      }
      if (_type == 5) {
        res.data.data.map(item=>{
          item.children.map(item2=>{
            item2.selected = false;
          });
        });
        data.list_gongyi = res.data.data;
      }
      if (_type == 1) {
        data.list_caizhi = res.data.data;
        data.list_caizhi.unshift({ id: '', title: '全部' });
      }
    }
  }.bind(this)).catch(function () {
  });
}

GetFilterTags(1);
GetFilterTags(2);
GetFilterTags(4);
GetFilterTags(5);


</script>

